<template>
  <div class="margin-navbar">
    <div class="d-flex flex-column w-100 font-body text-dark p-4">
      <h1 class="mt-3 mb-4">Come Lavoro</h1>
      <p>
        Inizio con un <strong>incontro conoscitivo</strong> in cui raccolgo
        un'accurata anamnesi del paziente, ascolto la storia della persona che
        si rivolge a me per capirne necessità e i bisogni.
      </p>
      <p>
        Attraverso l'utilizzo di tecniche manuali, ascolto, misurazione e test
        specifici effettuo una
        <strong>valutazione fisioterapica e osteopatica</strong> per inquadrare
        i problemi riportati dal soggetto in fase di anamnesi.
      </p>
      <p>
        Lo step successivo è <strong>comunicare</strong> al paziente su quali zone si vorrà
        intervenire, in modo da prendere coscienza del disagio fisico per
        poterlo affrontare insieme e spiegare le modalità con le quali lo
        faremo.
      </p>
      <div
        class="img-background mb-5"
        :style="{ backgroundImage: backgroundImage }"
      ></div>
      <p>
        Da qui si continua <strong>insieme</strong>, attraverso l'applicazione
        di tecniche manuali, utilizzando manovre muscolari, articolari,
        craniali, fasciali, viscerali, linfatiche e vascolari si va ad
        intervenire sulle zone interessate. Fondamentale in questa fase del
        trattamento sono la collaborazione ed il coinvolgimento del paziente, in
        modo da affinare la percezione del proprio corpo tramite respirazione,
        esercizi specifici, mantenimento di posture allenanti.
      </p>
      <p>
        L'obiettivo è dunque quello di creare una
        <strong>collaborazione terapeutica</strong>
        con la persona che si rivolge a me, guidandola verso un maggiore livello
        di benessere e una migliore conoscenza del proprio corpo. Questo avviene
        attraverso il miglioramento della consapevolezza di Se, la cooperazione
        per superare le difficoltà riscontrate e la responsabilizzazione del
        paziente stesso, per evitare che il problema si ripresenti.
      </p>
      <p>
        La fase conclusiva del trattamento prevede l'individuazione di
        comportamenti o posture dannosi, che portano all'insorgere del disagio
        fisico. Si cerca quindi di <strong>prevenire</strong> questi problemi mediante esercizi
        da poter fare a casa e fornendo indicazioni per uno stile di vita più
        equilibrato e adatto ad ogni individuo.
      </p>
    </div>
    <div class="d-flex w-100 justify-content-end p-5">
      <router-link to="/" tag="button" class="btn">
        Torna alla home
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

const image = require("@/assets/elia-studio.jpg");
console.log(image);

export default {
  name: "ComeLavoro",
  components: {},
  metaInfo: {
    title: "Elia Giovinazzo - Come Lavoro",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      {
        name: "description",
        content:
          "Studio di Osteopatia e Fisioterapia del Dott. Elia Giovinazzo. Professionista della salute a Genova. Riabilitazione, Trattamento e Prevenzione.",
      },
      {
        property: "og:title",
        content: "Elia Giovinazzo - Osteopatia  e Fisioterapia - Genova",
      },
      { property: "og:site_name", content: "Elia Giovinazzo" },
      { property: "og:type", content: "website" },
      { name: "robots", content: "index,follow" },
    ],
  },
  data() {
    return {
      backgroundImage: 'url("' + image + '")',
    };
  },
};
</script>

<style scoped></style>
